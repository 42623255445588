<template>
	<div class="ns-register-wrap" :style="{background: backgroundColor}" v-loading="loadingAd">
		<div class="el-row-wrap el-row-wrap-register" style="position: relative;">
			<el-row>
				<el-col>
					<el-carousel height="500px" class="ns-register-bg" @change="handleChange">
						<el-carousel-item  v-for="item in adList" :key="item.adv_id">
							<el-image style="height:500px; width: 100%;" :src="$img(item.adv_image)" fit="cover" />
						</el-carousel-item>
					</el-carousel>
				</el-col>
				<el-col :span="11" class="ns-register-form" style="position: absolute;right: 360px; z-index: 999;">
					<div class="grid-content bg-purple">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane :label="$lang('username',$route)" name="first">
								<el-form v-if="activeName == 'first'" :model="registerForm" ref="registerRef" :rules="registerRules">
									<el-form-item prop="email">
										<el-input v-model="registerForm.email" :placeholder="$lang('email',$route)">
											<template slot="prepend"><i class="iconfont icon-youxiang1"></i></template>
										</el-input>
									</el-form-item>

									<el-form-item prop="password">
										<el-input type="password" v-model="registerForm.password" autocomplete="off" :placeholder="$lang('password',$route)">
											<template slot="prepend"><i class="iconfont icon-mima"></i></template>
										</el-input>
									</el-form-item>
									
									<el-form-item prop="rePassword">
										<el-input type="password" v-model="registerForm.rePassword" autocomplete="off" :placeholder="$lang('rePassword',$route)">
											<template slot="prepend"><i class="iconfont icon-mima"></i></template>
										</el-input>
									</el-form-item>

									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="xy-wrap">
													<div class="iconfont" @click="check" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
													<div class="content">
														{{$lang('read_agree',$route)}}
														<b @click.stop="getAggrement">{{$lang('aggrement',$route)}}</b>
													</div>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
									
									<el-form-item>
										<el-button type="primary" class="rule-button" @click="register">{{$lang('button',$route)}}</el-button>
									</el-form-item>
									
									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="bg-purple-light">
													{{$lang('exist_account',$route)}}
													<router-link class="register" to="/login">{{$lang('login_button',$route)}}</router-link>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
								</el-form>
							</el-tab-pane>

							<el-tab-pane :label="$lang('emailRegister',$route)" name="second">
								<el-form v-if="activeName == 'second'" :model="registerForm" ref="registerRef" :rules="emailRules">
									<el-form-item prop="email">
										<el-input v-model="registerForm.email" :placeholder="$lang('email',$route)">
											<template slot="prepend"><i class="iconfont icon-youxiang1"></i></template>
										</el-input>
									</el-form-item>
									
									<el-form-item prop="dynacode">
										<el-input v-model="registerForm.dynacode" maxlength="4" :placeholder="$lang('email_dynacode', $route)">
											<template slot="prepend"><i class="iconfont icon-dongtaima"></i></template>
											<template slot="append">
												<div class="dynacode" :class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'" @click="sendEmailCode('registerRef')">
													{{ dynacodeData.codeText }}
												</div>
											</template>
										</el-input>
									</el-form-item>
									
									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="xy-wrap">
													<div class="iconfont" @click="check" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
													<div class="content">
														{{$lang('read_agree',$route)}}
														<b @click.stop="getAggrement">{{$lang('aggrement',$route)}}</b>
													</div>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
									
									<el-form-item>
										<el-button type="primary" class="rule-button" @click="register">{{$lang('button',$route)}}</el-button>
									</el-form-item>

									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="bg-purple-light">
													{{$lang('exist_account',$route)}}
													<router-link class="register" to="/login">{{$lang('login_button',$route)}}</router-link>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
								</el-form>
							</el-tab-pane>

							<el-tab-pane :label="$lang('mobileRegister',$route)" name="third" v-if="registerConfig.register && registerConfig.register.indexOf('mobile') != -1">
								<el-form v-if="activeName == 'third'" :model="registerForm" :rules="mobileRules" ref="registerRef" class="ns-register-mobile">
									<el-form-item prop="mobile">
										<el-input v-model="registerForm.mobile" :placeholder="$lang('mobile', $route)">
											<template slot="prepend"><i class="iconfont icon-shouji-copy"></i></template>
										</el-input>
									</el-form-item>

									<el-form-item prop="code">
										<el-input v-model="registerForm.code" autocomplete="off" :placeholder="$lang('code_trip',$route)" maxlength="4">
											<template slot="prepend"><i class="iconfont icon-yanzhengma"></i></template>
											<template slot="append">
												<img :src="captcha.img" mode class="captcha" @click="getCode" />
											</template>
										</el-input>
									</el-form-item>
			
									<el-form-item prop="dynacode">
										<el-input v-model="registerForm.dynacode" maxlength="4" :placeholder="$lang('mobile_dynacode',$route)">
											<template slot="prepend"><i class="iconfont icon-dongtaima"></i></template>
											<template slot="append">
												<div class="dynacode" :class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'"	@click="sendMobileCode('mobileRuleForm')">
													{{ dynacodeData.codeText }}
												</div>
											</template>
										</el-input>
									</el-form-item>
									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="xy-wrap">
													<div class="iconfont" @click="check" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
													<div class="content">
														{{$lang('read_agree',$route)}}
														<b @click.stop="getAggrement">{{$lang('aggrement',$route)}}</b>
													</div>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
									<el-form-item>
										<el-button type="primary" class="rule-button" @click="register">{{$lang('button',$route)}}</el-button>
									</el-form-item>
											
									<el-form-item>
										<el-row>
											<el-col :span="24">
												<div class="bg-purple-light">
													{{$lang('exist_account',$route)}}
													<router-link class="register" to="/login">{{$lang('login_button',$route)}}</router-link>
												</div>
											</el-col>
										</el-row>
									</el-form-item>
								</el-form>
							</el-tab-pane>
						</el-tabs>
					</div>
				</el-col>
			</el-row>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose" :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>

		<!-- 浮层区 -->
		<div class="floatLayer-wrap" v-show="is_show && reward" :style="{width:bgWidth,height:bgHeight}">
			<div class="reward-wrap">
				<img :src="$util.img('public/uniapp/register_reward/register_reward_img.png')" mode="widthFix" class="bg-img-head"></img>
				<img :src="$util.img('public/uniapp/register_reward/register_reward_money.png')" mode="widthFix" class="bg-img-money"></img>
				<img :src="$util.img('public/uniapp/register_reward/register_reward_head.png')" mode="widthFix" class="bg-img"></img>
				<div class="wrap">
					<div>
						<div class="reward-content">
							<div class="reward-item" v-if="reward && reward.point > 0">
								<div class="head">积分奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.point }}</span>
											<span class="type">积分</span>
										</div>
										<div class="desc">用于下单时抵现或兑换商品等</div>
									</div>
									<div class="tip" @click="closeRewardPopup('point')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.growth > 0">
								<div class="head">成长值</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.growth }}</span>
											<span class="type">成长值</span>
										</div>
										<div class="desc">用于提升会员等级</div>
									</div>
									<div class="tip" @click="closeRewardPopup('growth')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.balance > 0">
								<div class="head">红包奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.balance }}</span>
											<span class="type">元</span>
										</div>
										<div class="desc">不可提现下单时可用</div>
									</div>
									<div class="tip" @click="closeRewardPopup('balance')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.coupon_list.length > 0">
								<div class="head">优惠券奖励</div>
								<div class="content" v-for="(item, index) in reward.coupon_list" :key="index">
									<div class="info">
										<div>
											<span class="num coupon-name">{{ item.coupon_name }}</span>
										</div>
										<div class="desc" v-if="item.at_least > 0">
											满{{ item.at_least }}{{ item.type == 'discount' ? '打' + item.discount + '折' : '减' + item.money }}
										</div>
										<div class="desc" v-else>无门槛，{{ item.type == 'discount' ? '打' + item.discount + '折' : '减' + item.money }}</div>
									</div>
									<div class="tip" @click="closeRewardPopup('coupon')">立即查看</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close-btn" @click="closeRewardPopup()">
					<i class="iconfont icon-guanbi"></i>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import { getRegisiterAggrement,registerConfig,registerMobileCode,getRegisterReward,emailCode } from '@/api/auth/register';
import { adList,captcha } from '@/api/website';
import { showLoading, hideLoading } from '@/utils/loading';
import { logEvent } from "firebase/analytics";
export default {
	name: 'register',
	components: {},
	data() {
		var checkPassValidata = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(vue.$lang('rePassword')));
			} else if (value !== this.registerForm.password) {
				callback(new Error(vue.$lang('reNoPassword')));
			} else {
				callback();
			}
		};
		let self = this;
		var passwordValidata = function(rule, value, callback) {
			let regConfig = self.registerConfig;
			if (!value) {
				return callback(new Error(vue.$lang('password')));
			} else {
				if (regConfig.pwd_len > 0) {
					if (value.length < regConfig.pwd_len) {
						return callback(new Error(vue.$lang('common.pwd_len_one') + regConfig.pwd_len + vue.$lang('common.pwd_len_two')));
					} else {
						callback();
					}
				} else {
					if (regConfig.pwd_complexity != '') {
						let passwordErrorMsg = vue.$lang('common.pwd_complexity'),
							reg = '';
						if (regConfig.pwd_complexity.indexOf('number') != -1) {
							reg += '(?=.*?[0-9])';
							passwordErrorMsg += vue.$lang('common.numbers');
						} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
							reg += '(?=.*?[a-z])';
							passwordErrorMsg += vue.$lang('common.lower_case_letters');
						} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
							reg += '(?=.*?[A-Z])';
							passwordErrorMsg += vue.$lang('common.uppercase_letter');
						} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
							reg += '(?=.*?[#?!@$%^&*-])';
							passwordErrorMsg += vue.$lang('common.special_characters');
						} else {
							reg += '';
							passwordErrorMsg += '';
						}

						if (reg.test(value)) {
							return callback(new Error(passwordErrorMsg));
						} else {
							callback();
						}
					}
				}
			}
		};
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("手机号不能为空"))
			} else {
				const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("请输入正确的手机号"))
				}
			}
		};
		return {
			activeName: "first", // tab切换
			registerMode: 'account',
			registerForm: {
				username: '',
				email: '',
				password: '',
				checkPass: '',
				code: '',
				mobile: '',
				vercode: '',
				dynacode: '',
				key: ''
			},
			registerRules: {
				email: [
					{ required: true, message: vue.$lang('email'), trigger: 'blur' },
					{
						validator: function(rule, value, callback) {
						  if (/^([a-zA-Z0-9]+[_|\_|\.|\-]*)*([a-zA-Z0-9]?)+@([a-zA-Z0-9]+[_|\_|\.|\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/i.test(value) == false) {
							callback(new Error(vue.$lang('common.email_error')));
						  } else {
							callback();
						  }
						},
						trigger: "blur"
					}
				],
				password: [{
					required: true,
					validator: passwordValidata,
					trigger: 'blur'
				}],
				checkPass: [{
					required: true,
					validator: checkPassValidata,
					trigger: 'blur'
				}],
				code: [{ 
					required: true, 
					message: vue.$lang('code_trip'),
					trigger: 'blur' 
				}]
			},
			emailRules: {
				email: [
					{ required: true, message: vue.$lang('email'), trigger: 'blur' },
					{
						validator: function(rule, value, callback) {
						  if (/^([a-zA-Z0-9]+[_|\_|\.|\-]*)*([a-zA-Z0-9]?)+@([a-zA-Z0-9]+[_|\_|\.|\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/i.test(value) == false) {
							callback(new Error(vue.$lang('common.email_error')));
						  } else {
							callback();
						  }
						},
						trigger: "blur"
					}
				],
				dynacode: [{
					required: true,
					message: vue.$lang('email_dynacode'),
					trigger: "blur"
				}]
			},
			mobileRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "请输入短信动态码",
					trigger: "blur"
				}]
			},
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: vue.$lang('payDynacodeText'),
				isSend: false
			}, // 动态码
			ischecked: false,
			agreement: '',
			aggrementVisible: false,
			captcha: {
				// 验证码
				id: '',
				img: ''
			},
			registerConfig: {
				register: ''
			},
			loadingAd: true,
			adList: [],
			reward: null,
			is_show: false,
			backgroundColor: '',
			bgWidth: '',
			bgHeight: ''
		};
	},
	created() {
		this.getAdList()
		this.getCode();
		this.regisiterAggrement();
		this.getRegisterConfig();
		this.getRegisterReward();
		this.bgWidth = document.documentElement.clientWidth + "px";
		this.bgHeight = document.documentElement.clientHeight + "px";
	},
	methods: {
		getAdList() {
			adList({keyword: "NS_PC_LOGIN"}).then(res => {
				if (res.code == 0 && res.data.adv_list) {
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
				}

				this.loadingAd = false
			}).catch(err => {
				this.loadingAd = false
			})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		closeRewardPopup(type) {
			this.is_show = false;
		
			switch (type) {
				case 'point':
					this.$router.push('/member/my_point');
					break;
				case 'balance':
					this.$router.push('/member/account');
					break;
				case 'growth':
					this.$router.push('/member/index');
					break;
				case 'coupon':
					this.$router.push('/member/my_coupon');
					break;
				default:
					this.$router.push('/member/index');
					this.is_show = false;
			}
		},
		getRegisterReward() {
			getRegisterReward().then(res=>{
				if (res.code >= 0) {
					let data = res.data;
					if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value.growth > 0 || data.value.coupon_list.length > 0 )) {
						this.reward = data.value;
					}
				}
			}).catch(err=>{
			})
		},
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")

			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					return false
				}
			})
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					registerMobileCode({
						mobile: this.registerForm.mobile,
						captcha_id: this.captcha.id,
						captcha_code: this.registerForm.vercode
					}).then(res => {
						if (res.code >= 0) {
							this.registerForm.key = res.data.key
							if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
								this.dynacodeData.timer = setInterval(() => {
									this.dynacodeData.seconds--
									this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
								}, 1000)
							}
						}
					}).catch(err => {
						this.$message.error(err.message)
					})
				} else {
					return false
				}
			})
		},
		handleClick(tab, event) {
			if (this.activeName == "first") {
				this.registerMode = "account"
			} else if (this.activeName == "second") {
				this.registerMode = "email"
			} else {
				this.registerMode = "mobile"
			}
		},
		check() {
			this.ischecked = !this.ischecked;
		},
		toLogin() {
			this.$router.push('/login');
		},
		//  获取注册配置
		getRegisterConfig() {
			registerConfig().then(res => {
				if (res.code >= 0) {
					this.registerConfig = res.data.value;
					if (this.registerConfig.register == '') {
						if(this.registerConfig.third_party == 1){
							this.$message({
								message: '平台未启用普通注册注册',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({ name: 'login', params:{ third_party: true } });
								}
							});
						}else{
							this.$message({
								message: '平台未启用注册',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push('/');
								}
							});
						}
					}
				}
			}).catch(err => {
			});
		},
		// 账号密码注册
		register() {
			this.$refs.registerRef.validate(valid => {
				if (valid) {
					if (!this.ischecked) {
						return this.$message({
							message: vue.$lang('read_agree_trip'),
							type: 'warning'
						});
					}

					if (this.registerMode == 'account') {
						var url = 'member/register_token';
						var data = {
							username: this.registerForm.email.trim(),
							password: this.registerForm.password
						};
						
					} else if (this.registerMode == 'email') {
						var url = 'member/register_email';
						var data = {
							code: this.registerForm.dynacode,
							key: this.registerForm.key,
							email: this.registerForm.email.trim(),
							password: this.registerForm.password
						};
					}

					this.$store.dispatch(url, data).then(res => {
						if (res.code >= 0) {
							logEvent(this.$analytics, 'register', {
								'app_name': 'pc',
							})
							if (this.reward) {
								this.is_show = true;
							} else {
								this.$router.push('/member/index');
							}
						}
					}).catch(err => {
						this.$message.error(err.message);
						//this.getCode();
					});
				} else {
					return false;
				}
			});
		},
		// 手机号注册
		registerMobile() {
			this.$refs.mobileRuleForm.validate(valid => {
				if (valid) {
					if (!this.ischecked) {
						return this.$message({
							message: '请先阅读协议并勾选',
							type: 'warning'
						});
					}
					var data = {
						mobile: this.registerForm.mobile,
						key: this.registerForm.key,
						code: this.registerForm.dynacode
					};
					if (this.captcha.id != '') {
						data.captcha_id = this.captcha.id;
						data.captcha_code = this.registerForm.code;
					}
					this.$store.dispatch('member/registerMobile_token', data).then(res => {
						if (res.code >= 0) {
							if (this.reward) {
								this.is_show = true;
							} else {
								this.$router.push('/member/index');
							}
						}
					}).catch(err => {
						this.$message.error(err.message);
						this.getCode();
					});
				} else {
					return false;
				}
			});
		},
		aggrementClose() {
			this.aggrementVisible = false;
		},
		// 获取协议
		regisiterAggrement() {
			getRegisiterAggrement().then(res => {
				if (res.code >= 0) {
					this.agreement = res.data;
				}
			}).catch(err => {
				console.log(err.message)
			});
		},
		getAggrement() {
			this.aggrementVisible = true;
		},
		// 获取验证码
		getCode() {
			captcha({
				captcha_id: 'this.captcha.id'
			}).then(res => {
				if (res.code >= 0) {
					this.captcha = res.data;
					this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
				}
			}).catch(err => {
				this.$message.error(err.message);
			});
		},
		sendEmailCode(formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")
			this.$refs[formName].validateField("email", valid => {
				if (!valid) {
					showLoading();
					emailCode({
						email: this.registerForm.email
					}).then(res => {
						hideLoading();
						if (res.code >= 0) {
							this.registerForm.key = res.data.key
							if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
								this.dynacodeData.timer = setInterval(() => {
									this.dynacodeData.seconds--
									this.dynacodeData.codeText = this.dynacodeData.seconds + "s" + vue.$lang('common.reacquire')
								}, 1000)
							}
						}
					}).catch(err => {
						hideLoading();
						this.$message.error(err.message)
					})
				} else {
					return false
				}
			})
		}
	}
};
</script>
<style lang="scss" scoped>
.floatLayer-wrap{
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,.5);
	z-index: 999;
	position: absolute;
	.reward-wrap {
		width: 400px;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.bg-img {
			width: 100%;
			will-change: transform;
		}
		.bg-img-head{
			position: absolute;
			top: -90px;
			width: 100%;
	
		}
		.bg-img-money{
			position: absolute;
			width: 100%;
			left: -20px;
			top: 80px;
			z-index: 10;
		}
	
		.wrap {
			width: calc(100% - 1px);
			height: 100%;
			background-color: #ef3030;
			margin-top: -40px;
			padding-bottom: 30px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			& > div {
				position: relative;
			}
		}
	
		.reward-content {
			margin: 0 25px 0 25px;
		}
	
		.reward-item {
			.head {
				color: #fff;
				text-align: center;
				line-height: 1;
				margin: 10px 0;
			}
	
			.content {
				display: flex;
				padding: 8px 13px;
				background: #fff;
				border-radius: 5px;
				margin-bottom: 5px;
				.info {
					flex: 1;
				}
	
				.tip {
					color: #ff222d;
					padding: 5px 0 5px 15px;
					width: 70px;
					line-height: 1.5;
					letter-spacing: 1px;
					border-left: 1px dashed #e5e5e5;
					height: 40px;
					line-height: 40px;
				}
	
				.num {
					font-size: 26px;
					color: #ff222d;
					font-weight: bolder;
					line-height: 1;
				}
				.coupon-name{
					font-size: 19px;
				}
	
				.type {
					font-size: $ns-font-size-base;
					margin-left: 5px;
					line-height: 1;
				}
	
				.desc {
					margin-top: 4px;
					color: $base-color;
					font-size: $ns-font-size-base;
					line-height: 1;
				}
			}
		}
	
		.btn {
			position: absolute;
			width: calc(100% - 50px);
			bottom: 20px;
			left: 25px;
	
			.btn-img {
				width: 100%;
			}
		}
	}
}
.close-btn{
	text-align: center;
	margin-top: 20px;
	.iconfont{
		color: #fff;
		font-size: 20px;
	}
}
.clear{
	content: '';
	display: block;
	clear: both;
}

.icon-xuanze-duoxuan {
	color: $base-color;
}
.xy-wrap {
	display: flex;
	align-items: center;
	font-size: $ns-font-size-base;
	cursor: pointer;
	.iconfont {
		display: flex;
		align-content: center;
	}
	.toLogin {
		cursor: pointer;
	}
	.content {
		margin-left: 3px;
		b {
		color: $base-color;
		}
	}
}
.ns-register-wrap {
	width: 100%;
	height: 500px;
	min-width: $width;

	.el-row-wrap-register {
		// width: 1200px;
		margin: 0 auto;

		.ns-register-bg {
			// margin-top: 40px;
			el-image {
				height: 500px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.ns-register-form {
			width: 400px;
			margin-left: 50px;
			background: #ffffff;
			margin-top: 20px;

			.el-form {
				.captcha {
					vertical-align: top;
					max-width: inherit;
					max-height: 38px;
					line-height: 38px;
					cursor: pointer;
				}

				.dynacode {
					cursor: pointer;
				}

				[class*=' el-icon-'],
				[class^='el-icon-'] {
					font-size: 16px;
				}
			}

			.grid-content {
				padding: 10px 20px;
			}

			.el-form-item__error {
				padding-left: 50px;
			}

			button {
				width: 100%;
			}

			.ns-forget-pass {
				text-align: right;
			}

			i {
				font-size: 18px;
			}

			.bg-purple-light {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.register {
					color: #fd274a;
				}
			}
		}
	}
}

.rule-button {
	background-color: #fd274a;
}
.ns-register-form {
	.el-form-item__error {
		/* 错误提示信息 */
		padding-left: 57px;
	}

	/* 立即注册 */
	.el-form-item__content {
		line-height: 20px;
	}
}
</style>
